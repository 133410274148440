@font-face {
    font-family: SFProDisplay;
    font-weight: normal;
    src: url("../fonts/SFProDisplay-Regular.woff") format('woff');
}

@font-face {
    font-family: SFProDisplay;
    font-weight: bold;
    src: url("../fonts/SFProDisplay-Bold.woff") format('woff');
}

@font-face {
    font-family: SFProText;
    src: url("../fonts/SFProText-Regular.woff") format('woff');
}
