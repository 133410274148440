.textShadow {
  width: 100%;
  position: relative;
}

.textShadow::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 40px;
  background: linear-gradient(
                  0deg,
                  rgba(255, 255, 255, 1) 0%,
                  rgba(255, 255, 255, 0.9) 35%,
                  rgba(255, 255, 255, 0.3) 100%
  );
  top: -40px;
}
